// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "@fortawesome/fontawesome-free/js/all";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// MENU
document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('navigation-menu').addEventListener('click', function () {
    const menu = document.getElementById('canvas-menu');
    const menuBg = document.getElementById('nav-bg');
    const root = document.getElementsByTagName('html')[0];

    if (menu.classList.contains('open')) {
      menu.classList.remove('open');
      this.classList.remove('open');
      menuBg.classList.remove('active');
      root.classList.remove('oy');
    } else {
      menu.classList.add('open');
      this.classList.add('open');
      menuBg.classList.add('active');
      root.classList.add('oy');
    }
  });

  document.getElementById('nav-bg').addEventListener('click', function () {
    const menu = document.getElementById('canvas-menu');
    const navMenu = document.getElementById('navigation-menu');
    const root = document.getElementsByTagName('html')[0];

    if (menu.classList.contains('open')) {
      menu.classList.remove('open');
      navMenu.classList.remove('open');
      root.classList.remove('oy');
      this.classList.remove('active');

    }
  });

  document.querySelectorAll(".menu-link").forEach(element => {
    element.addEventListener('click', function () {
      const menu = document.getElementById('canvas-menu');
      const navMenu = document.getElementById('navigation-menu');
      const root = document.getElementsByTagName('html')[0];
      const menuBg = document.getElementById('nav-bg');

      if (menu.classList.contains('open')) {
        menu.classList.remove('open');
        navMenu.classList.remove('open');
        root.classList.remove('oy');
        menuBg.classList.remove('active');
      }
    });
  });
});